<template>
  <div class="recharge-process">
    <el-dialog
      :visible="rechargeDialog"
      :title="dialogTitle"
      width="30%"
      :before-close="handleClose"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      @close="closeDialog"
      v-if="showDialog"
    >
      <div class="content">
        <!-- 未支付时弹框 -->
        <el-form
          v-if="rechargeStatusData == 'no'"
          label-position="top"
          :model="form"
          ref="form"
          :rules="rules"
          label-width="50px"
        >
          <el-form-item :label="firItemTitle" prop="account">
            <el-input
              :readonly="isReadonly"
              type="number"
              v-model.number="form.account"
            ></el-input>
          </el-form-item>
          <el-form-item :label="secItemTitle" prop="type">
            <el-radio-group v-model="form.type">
              <el-radio label="微信"
                ><svg class="weixinicon" aria-hidden="true">
                  <use xlink:href="#icon-weixin11"></use></svg
              ></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <!-- 二维码弹框 -->
        <div class="qr" v-if="rechargeStatusDataData == 'qr'" v-loading="isRes">
          <div class="money">
            {{ $t("label.partnerCloud.partnerAccount.payment") }}
            <span>{{ currency }} {{ thousands(form.account) }}</span>
          </div>
          <div class="money">
            {{ $t("label.partnerCloud.partnerAccount.order")
            }}<span>{{ orderNumber }}</span>
          </div>
          <div class="qrcode-box">
            <div id="qrcode" ref="qrcode"></div>
            <div class="tooltip">
              {{ $t("label.partnerCloud.partnerAccount.useWechat") }}
            </div>
          </div>
        </div>
        <!-- 支付成功弹框 -->
        <div class="success" v-if="rechargeStatusData == 'success'">
          <div class="status">{{ paySuccess }}</div>
          <div class="money">
            {{ payMoney }}：{{ currency }} {{ form.account }}
          </div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-success"></use>
          </svg>
        </div>
        <!-- 支付失败弹框 -->
        <div class="success" v-if="rechargeStatusData == 'fail'">
          <div class="status">{{ payFail }}</div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-fail"></use>
          </svg>
        </div>
      </div>
      <!-- 充值页面 -->
      <span
        slot="footer"
        class="dialog-footer"
        v-if="rechargeStatusData == 'no'"
      >
        <el-button @click="cancle">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="recharge('form')">{{
          $t("label.partnerCloud.partnerAccount.toPay")
        }}</el-button>
      </span>
      <!-- 二维码页面 -->
      <span
        slot="footer"
        class="dialog-footer"
        v-if="rechargeStatusData == 'qr'"
      >
        <el-button type="primary" @click="query">{{
          $t("label.partnerCloud.partnerAccount.completed")
        }}</el-button>
      </span>
      <!-- 充值成功页面 -->
      <span
        slot="footer"
        class="dialog-footer"
        v-if="rechargeStatusData == 'success' || rechargeStatusData == 'fail'"
      >
        <el-button @click="close">{{ $t("label.tabpage.closez") }}</el-button>
        <el-button v-show="payAgain" type="primary" @click="rechargeAgain">{{
          $t("label.partnerCloud.partnerAccount.again")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import {
  checkIsRecharge,
  doUnifiedOrder,
  isRechargeSuccess,
  isOrderSuccess,
  clouseOrder,
  getTradeStatus,
} from "../api";
import debounce from "lodash.debounce";
export default {
  props: {
    paySuccess: {
      type: String,
      default() {
        return this.$i18n.t("label.partnerCloud.partnerAccount.topUpSuccess");
      },
    },
    payMoney: {
      type: String,
      default() {
        return this.$i18n.t("label.partnerCloud.partnerAccount.topUpAmount");
      },
    },
    payFail: {
      type: String,
      default() {
        return this.$i18n.t("label.partnerCloud.partnerAccount.topUpFailure");
      },
    },
    rechargeDialog: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default() {
        return this.$i18n.t("label.partnerCloud.partnerAccount.prepaidPhone");
      },
    },
    firItemTitle: {
      type: String,
      default() {
        return this.$i18n.t("label.partnerCloud.partnerAccount.topUpAmount");
      },
    },
    secItemTitle: {
      type: String,
      default() {
        return this.$i18n.t("label.partnerCloud.partnerAccount.way");
      },
    },
    // 是否显示再次充值按钮
    payAgain: {
      type: Boolean,
      default: true,
    },
    // 是否只读
    isReadonly: {
      type: Boolean,
      default: false,
    },
    // 显示步骤
    dataOrign: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 充值状态
    rechargeStatus: {
      type: String,
      default: "no",
    },
    // 是否展示弹窗
    showDialog: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      //币种
      currency: this.$store.state.currencyCode,
      //轮询
      interval: null,
      //判断二维码是否出来
      isRes: false,
      //订单号
      orderNumber: "",
      //充值状态
      rechargeStatusData: this.rechargeStatus, //包含五步骤 no 未支付 qr  二维码界面  payIng 正在支付  success  成功  fail 失败
      form: {
        account: "",
        type: "微信",
      },
      rules: {
        account: [
          {
            required: true,
            message: this.$i18n.t("label.partnerCloud.partnerAccount.noempty"),
          },
          {
            type: "number",
            message: this.$i18n.t(
              "label.partnerCloud.partnerAccount.numericValue"
            ),
          },
          {
            pattern:
              /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: this.$i18n.t(
              "label.partnerCloud.partnerAccount.twoDecimal"
            ),
          },
        ],
        type: [
          {
            required: true,
            message: this.$i18n.t(
              "label.partnerCloud.partnerAccount.choosePayment"
            ),
            trigger: "change",
          },
        ],
      },
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;
  },
  mounted() {
    this.checkIsRecharge();
  },
  methods: {
    // 设置千分位样式  dj
    thousands(num) {
      if (num) {
        var parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    },
    closeDialog() {
      this.rechargeStatusData = "no";
      // eslint-disable-next-line vue/no-mutating-props
      this.showDialog = false;
      (this.form = {
        account: "",
        type: "",
      }),
        clearInterval(this.interval);
    },
    //查询交易状态
    async getTradeStatus(tradetype) {
      let result = await getTradeStatus({
        tradetype: tradetype,
        tradeid: this.orderNumber,
      });
      if (result.result) {
        if (result.returnCode == "1") {
          clearInterval(this.interval);
          this.rechargeStatusData = "success";
          this.$emit("paySuccess", true);
        } else if (result.returnCode == "3") {
          clearInterval(this.interval);
          this.rechargeStatusData = "fail";
        }
      }
    },
    //充值是否完成接口
    async isRechargeSuccess() {
      let result = await isRechargeSuccess({ tradeNo: this.orderNumber });
      if (result.result) {
        if (result.returnCode == "1") {
          clearInterval(this.interval);
          this.rechargeStatusData = "success";
          this.$emit("paySuccess", true);
        } else if (result.returnCode == "3") {
          clearInterval(this.interval);
          this.rechargeStatusData = "fail";
        }
      }
    },
    // 订单是否完成接口
    async orderSuccess(data) {
      let result = await isOrderSuccess(data);
      if (result.result) {
        if (result.returnCode == "1") {
          clearInterval(this.interval);
          this.rechargeStatusData = "success";
          this.$emit("paySuccess", true);
        } else if (result.returnCode == "3") {
          clearInterval(this.interval);
          this.rechargeStatusData = "fail";
        }
      }
    },
    //判断是否完成支付按钮
    query: debounce(
      function () {
        if (
          this.firItemTitle ==
          this.$i18n.t("label.partnerCloud.orderingPlatform.paymentAmount")
        ) {
          this.getTradeStatus("order");
        } else {
          this.getTradeStatus("charge");
        }
      },
      3000,
      { leading: true, trailing: false }
    ),
    //获取金额上限值
    async checkIsRecharge() {
      let res = await checkIsRecharge({ rechargeamount: 500 });
      if (res.result) {
        if (res.data.restrain == "true") {
          // `充值金额要在0.1到${res.data.upperlimit}之间`
          this.rules.account.push({
            type: "number",
            min: 0,
            max: Number(res.data.upperlimit),
            message: this.$i18n.t(
              "label.partnerCloud.partnerAccount.recharge",
              { upperlimit: res.data.upperlimit }
            ),
          });
        } else {
          this.rules.account.push({
            type: "number",
            min: 0.1,
            max: 9999999.99,
            message: this.$i18n.t(
              "label.partnerCloud.partnerAccount.recharge",
              { upperlimit: 9999999.99 }
            ),
          });
        }
      }
    },
    //关闭弹框
    async handleClose() {
      if (this.orderNumber != "") {
        let result = await clouseOrder({ orderNumber: this.orderNumber });
        if (result.result) {
          //
        }
      }
      this.$emit("rechargeCancle");
    },
    //前往支付
    recharge(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          //充值下单
          this.rechargeStatusData = "qr";
          this.isRes = true;
          let result = await doUnifiedOrder({
            money: this.form.account,
            paymentMethod: "wxpay",
            payway: "Recharge",
            url: window.location.origin,
          });
          if (result.result) {
            this.isRes = false;
            this.orderNumber = result.data.out_trade_no;
            setTimeout(() => {
              // eslint-disable-next-line no-unused-vars
              let qrcode = new QRCode("qrcode", {
                width: 165,
                height: 165,
                text: result.data.code_url,
                render: "canvas",
                backgroound: "#f0f",
                foreground: "#ff0",
                correctLevel: 0,
              });
            }, 200);
            this.interval = window.setInterval(() => {
              this.isRechargeSuccess();
            }, 2000);
          }
        } else {
          return false;
        }
      });
    },
    close() {
      if (this.payAgain) {
        this.form = {
          account: "",
          type: "",
        };
      }
      this.$emit("rechargeCancle");
      this.rechargeStatusData = "no";
    },
    rechargeAgain() {
      this.rechargeStatusData = "no";
    },
    //取消支付
    cancle() {
      if (this.payAgain) {
        this.form = {
          account: "",
          type: "",
        };
      }
      this.$emit("rechargeCancle");
    },
  },
  watch: {
    async dataOrign(val) {
      this.orderNumber = val.orderid;
      this.isRes = true;
      this.form.account = val.money;
      let result = await doUnifiedOrder(val);
      if (result.result) {
        // eslint-disable-next-line vue/no-mutating-props
        this.showDialog = true;
        this.isRes = false;
        setTimeout(() => {
          // eslint-disable-next-line no-unused-vars
          let qrcode = new QRCode("qrcode", {
            width: 165,
            height: 165,
            text: result.data.code_url,
            render: "canvas",
            backgroound: "#f0f",
            foreground: "#ff0",
            correctLevel: 0,
          });
        }, 200);
        this.interval = window.setInterval(() => {
          setTimeout(this.orderSuccess({ orderid: val.orderid }), 0);
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.recharge-process {
  ::v-deep .el-radio-group {
    transform: translateY(-12px);
  }
  .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    .status {
      margin-bottom: 10px;
    }
    .money {
      color: #080707;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 30px;
    }
    img {
      width: 70px;
    }
  }

  .content {
    // padding-bottom: 10px;
  }
  ::v-deep .el-button--primary {
    color: white;
  }
  ::v-deep .el-form--label-top .el-form-item__label {
    padding: 10px 0 0;
  }
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }
  .qrcode-box {
    text-align: center;
    padding: 10px;
    .tooltip {
      margin-top: 20px;
    }
  }
  #qrcode {
    display: flex;
    align-items: baseline;
    justify-content: center;
  }
  .money {
    color: #888;
    margin-bottom: 6px;
    span {
      color: #080707;
    }
  }
}
.weixinicon {
  display: inline-block;
  width: 70px;
  height: 19px;
  transform: translateY(5px);
}
.success .icon {
  width: 70px;
  height: 60px;
}
</style>