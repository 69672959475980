import axios from '@/config/httpConfig'
// 查询伙伴账户与流水
export function queryPartnerFund(data) {
    return axios.post('partnerFund/queryPartnerFund', data)
}
// 充值下单
export function doUnifiedOrder(data) {
    return axios.post('PayApi/doUnifiedOrder', data)
}
// 查询交易状态
export function getTradeStatus(data) {
    return axios.post('/PayApi/getTradeStatus', data)
}
// 关闭订单
export function clouseOrder(data) {
    return axios.post('PayApi/clouseOrder', data)
}
// 充值是否完成
export function isRechargeSuccess(data) {
    return axios.post('PayApi/isRechargeSuccess', data)
}
// 查询是否交易成功
export function isOrderSuccess(data) {
    return axios.post('/PayApi/isOrderSuccess', data)
} 
// 查询返利收入
export function queryRebateRevenue(data) {
    return axios.post('/partnerFund/queryRebateRevenue', data)
}
// 查询返利支出
export function queryRebateExpenditure(data) {
    return axios.post('/partnerFund/queryRebateExpenditure', data)
}
// 查询支付设置
export function getPaymentSettings(data) {
    return axios.post('partnerSetup/getPaymentSettings', data)
}
// 查询返利收入可用返利余额临期记录
export function queryRecentlyRevenue(data) {
    return axios.get('partnerFund/queryRecentlyRevenue', data)
}
// 校验是否超过限额
export function checkIsRecharge(data) {
    return axios.post('PayApi/checkIsRecharge', data)
}
